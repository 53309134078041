<script lang="ts">
type Props = {
  alt: string;
  theme?: 'dark' | 'light';
};
</script>

<script setup lang="ts">
const props = withDefaults(defineProps<Props>(), {
  theme: 'dark',
});

const {
  public: { cdnUrl },
} = useRuntimeConfig();

const color = props.theme === 'dark' ? 'light' : 'dark';
const src = `${cdnUrl}/images/redesign/logos/logo-${color}.svg`;
</script>

<template>
  <img class="logo" width="96" height="41" :src="src" :alt="alt" />
</template>

<style lang="scss" scoped>
.logo {
  --custom-logo-width: 6rem;

  width: var(--custom-logo-width);

  will-change: width;

  @media only screen and (min-width: 576px) {
    --custom-logo-width: 6.75rem;
  }

  @media screen and (min-width: 992px) {
    --custom-logo-width: 7.5rem;
  }
}
</style>
